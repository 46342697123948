import * as React from 'react';
import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
// eslint-disable-next-line camelcase
import { unstable_serialize, SWRConfig } from 'swr';

import { IndexOrg } from 'organisms';
import {
  getProductList,
  getProductListReturn,
  getProductListProps,
  getBlogList,
  getBlogListReturn,
  getBlogListProps,
} from 'api';

type StaticReturn = {
  fallback: Record<string, getProductListReturn | getBlogListReturn>;
};

export const getStaticProps: GetStaticProps<StaticReturn> = async () => {
  const productListProps = {
    api: 'getProductList',
    page: 1,
    pageSize: 10,
    sortBy: 'bestMatch',
  } as getProductListProps;

  const productListData = await getProductList(productListProps);

  const blogListProps = {
    api: 'getBlogList',
    page: 1,
    pageSize: 6,
  } as getBlogListProps;

  const blogListData = await getBlogList(blogListProps);

  return {
    props: {
      fallback: {
        [unstable_serialize(productListProps)]: productListData,
        [unstable_serialize(blogListProps)]: blogListData,
      },
    },
  };
};

export default function Page(props: StaticReturn): JSX.Element {
  const { fallback } = props;

  return (
    <>
      <NextSeo
        title="cafn.co | Roasted Coffee Beans from Passionate Roasters"
        description="แพลตฟอร์มเมล็ดกาแฟที่มากกว่าการซื้อขาย สนับสนุนทุกความหลงใหลในกาแฟ เชื่อมโยงคนรักการดื่มกาแฟกับคนคั่วเมล็ดกาแฟ และทำให้เรื่องกาแฟง่ายและมีความสุขอยู่เสมอ"
        canonical="https://www.cafn.co"
      />
      <SWRConfig value={{ fallback }}>
        <IndexOrg />
      </SWRConfig>
    </>
  );
}

import * as React from 'react';
import { Box, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Link from 'next/link';

import useWindowDimensions from 'hook/useWindowDimensions';
import { indexPromoData } from 'api';

const PromoBannerSkeleton = (): JSX.Element => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const [skeletonHeight, setSkeletonHeight] = React.useState('unset');

  React.useEffect(() => {
    if (width) {
      if (width <= theme.breakpoints.values.sm) {
        const ratio = 500 / 600;
        setSkeletonHeight(`${width * ratio}px`);
      } else if (width <= theme.breakpoints.values.md) {
        setSkeletonHeight('500px');
      } else if (width <= theme.breakpoints.values.lg) {
        const ratio = 210 / 1200;
        setSkeletonHeight(`${width * ratio}px`);
      } else {
        setSkeletonHeight('210px');
      }
    }
  }, [width]);

  return (
    <Skeleton variant="rectangular" width="100%" height={skeletonHeight} />
  );
};

type PromoBannerImgProps = {
  data: indexPromoData;
  heightDict: { xs: string; sm: string; md: string; lg: string };
};
const PromoBannerImg = (props: PromoBannerImgProps): JSX.Element => {
  const theme = useTheme();
  const { data, heightDict } = props;
  const { linkType, linkPath, openLinkInNewWindows } = data;

  const imgComponent = (
    <Box
      component="picture"
      sx={{ display: 'block', height: heightDict, width: '100%' }}
    >
      <source
        media={`(min-width: ${theme.breakpoints.values.lg}px)`}
        srcSet={data.longBanner}
      />
      <source
        media={`(min-width: ${theme.breakpoints.values.md}px)`}
        srcSet={data.cropLongBanner}
      />
      <source
        media={`(min-width: ${theme.breakpoints.values.sm}px)`}
        srcSet={data.squareBanner}
      />
      <Box
        component="img"
        src={data.cropSquareBanner}
        sx={{
          height: {
            xs: 'unset',
            sm: '100%',
            md: 'unset',
            lg: '100%',
          },
          width: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );

  if (linkType === 'External') {
    return (
      <a href={linkPath} target={openLinkInNewWindows ? '_blank' : '_self'}>
        {imgComponent}
      </a>
    );
  }
  if (linkType === 'Internal') {
    return (
      <Link
        href={linkPath}
        passHref
        prefetch
        target={openLinkInNewWindows ? '_blank' : '_self'}
      >
        <a href={linkPath}>{imgComponent}</a>
      </Link>
    );
  }

  return imgComponent;
};

export type PromoBannerProps = {
  data?: indexPromoData;
};
const PromoBanner = (props: PromoBannerProps): JSX.Element => {
  const { data } = props;
  const heightDict = {
    xs: 'unset',
    sm: '500px',
    md: 'unset',
    lg: '210px',
  };
  // to really max width at 4k it should be 3,840px with 100% display scaling
  // at that point i really think it too small and unusable
  // so let's use 3,840/150% = 2560 (150% is recommend scaling from windows on 4k monitor)
  const maxWidth = '2560px';

  return (
    // without lineHeight 0, there will be a padding on bottom, don't know why
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        lineHeight: 0,
        height: heightDict,
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth,
        }}
      >
        {data ? (
          <PromoBannerImg heightDict={heightDict} data={data} />
        ) : (
          <PromoBannerSkeleton />
        )}
      </Box>
    </Box>
  );
};

export default PromoBanner;

import useSWR from 'swr';

import { getBlogList, getBlogListProps, getBlogListReturn } from 'api';

const useBlogList = (
  args: getBlogListProps & { initialData?: getBlogListReturn },
): {
  isLoading: boolean;
  isError: boolean;
  data?: getBlogListReturn;
} => {
  const { initialData, ...getBlogListArgs } = args;

  const { data, error } = useSWR(
    {
      ...{ api: 'getBlogList' },
      ...getBlogListArgs,
    },
    getBlogList,
    {
      fallbackData: initialData,
    },
  );

  return {
    isLoading: !error && !data,
    isError: error,
    data,
  };
};
export default useBlogList;

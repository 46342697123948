import { Caffe, CaffePromise } from 'apiclient';
import { AxiosRequestConfig } from 'axios';
import { Blog } from 'types/blog';

const caffe = new Caffe();

export type getBlogDetailProps = {
  name: string;
  preview?: boolean;
} & {
  axiosConfig?: AxiosRequestConfig;
};

export type getBlogDetailReturn = Pick<
  Blog,
  | 'title'
  | 'subTitle'
  | 'author'
  | 'articleDate'
  | 'dateModified'
  | 'imageUrl'
  | 'tags'
  | 'slug'
  | 'content'
>;

const blogDetail = async (
  props: getBlogDetailProps,
): CaffePromise<getBlogDetailReturn> => {
  const { axiosConfig, ...apiProps } = props;

  return caffe.request({
    method: 'GET',
    url: '/api/method/caff.e.blog.query.blog',
    params: apiProps,
    ...axiosConfig,
  });
};
export default blogDetail;

import { format, parseISO, differenceInDays } from 'date-fns';

export const parseFormatISO = (
  dateString: string | null,
  dateFormat: string,
): string => {
  return dateString ? format(parseISO(dateString), dateFormat) : '';
};

export const parsePreFormatISO = (
  dateString: string | null,
  dateFormat: 'display' | 'timestamp' | 'datestamp',
): string => {
  const formatMap = {
    display: 'MMM dd, yyyy',
    datestamp: 'yyyy-MM-dd',
    timestamp: 'yyyy-MM-dd HH:mm',
  };
  return parseFormatISO(dateString, formatMap[dateFormat]);
};

export const getNextRoastInDays = (
  dateString: string,
  todayAs?: Date,
): number => {
  const nextRoastDate = parseISO(dateString);

  const today = todayAs || new Date();
  today.setHours(0, 0, 0, 0);

  const daysDiff = differenceInDays(nextRoastDate, today);
  return daysDiff;
};

import * as React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Box, ButtonBase } from '@mui/material';

import { ProductCard } from 'components/product';
import { Product } from 'types/product';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const Arrow = ({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}): JSX.Element => {
  const btnOnClick = () => {
    onClick();
  };

  return (
    <ButtonBase
      onClick={btnOnClick}
      sx={{
        alignItems: 'center',
        width: '60px',
        display: {
          xs: 'none',
          sm: 'none',
          md: 'none',
          lg: 'flex',
        },
        visibility: disabled ? 'hidden' : undefined,
        justifyContent: 'center',
      }}
    >
      {children}
    </ButtonBase>
  );
};

const LeftArrow = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={scrollPrev}>
      <ChevronLeft />
    </Arrow>
  );
};

const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={scrollNext}>
      <ChevronRight />
    </Arrow>
  );
};

export type ScrollCardProps = {
  product?: Omit<Product, 'description'>;
  itemId: string;
};

export const ScrollCard = (props: ScrollCardProps): JSX.Element => {
  const { product } = props;

  return (
    <Box sx={{ width: '300px', padding: '7.5px' }}>
      {/* inner width = 285px */}
      <ProductCard product={product} />
    </Box>
  );
};

export type ProductRowsProps = {
  productList: Omit<Product, 'description'>[];
};

const ProductRows = (props: ProductRowsProps): JSX.Element => {
  const { productList } = props;

  return (
    <Box
      // disable scroll bar
      sx={{
        '& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar':
          {
            display: 'none',
          },
        '& .react-horizontal-scrolling-menu--scroll-container': {
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        },
        'marginLeft': {
          xs: '0',
          sm: '0',
          md: '0',
          lg: '-60px',
        },
        'marginRight': {
          xs: '0',
          sm: '0',
          md: '0',
          lg: '-60px',
        },
      }}
    >
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {productList.map((product) => (
          <ScrollCard
            itemId={product.productCodeFull}
            product={product}
            key={product.productCodeFull}
          />
        ))}
      </ScrollMenu>
    </Box>
  );
};
export default ProductRows;

import * as React from 'react';
import { Box, Container, Grid } from '@mui/material';

import { BlogSummary } from 'types/blog';
import BlogCard from '../BlogCard';

type BlogListTemplateProps = {
  children: React.ReactNode;
};
const BlogListTemplate = (props: BlogListTemplateProps): JSX.Element => {
  const { children } = props;
  return (
    <Container maxWidth="lg" sx={{ mt: '30px' }}>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </Container>
  );
};

type BlogListContentProps = {
  blogList: Omit<BlogSummary, 'subTitle'>[];
};
const BlogListContent = (props: BlogListContentProps): JSX.Element => {
  const { blogList } = props;

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {blogList.map((blog) => (
          <BlogCard key={blog.slug} data={blog} />
        ))}
      </Grid>
    </Container>
  );
};

const BlogListEmpty = (): JSX.Element => {
  return <Box>Empty</Box>;
};

const BlogListSkeleton = (): JSX.Element => {
  return (
    <BlogListTemplate>
      <BlogCard />
      <BlogCard />
      <BlogCard />
      <BlogCard />
      <BlogCard />
      <BlogCard />
    </BlogListTemplate>
  );
};

type BlogListErrorProps = {
  errorMsg: string;
};
const BlogListError = (props: BlogListErrorProps): JSX.Element => {
  const { errorMsg } = props;
  return <Box>Error: {errorMsg}</Box>;
};

export type BlogListProps = {
  data?: BlogListContentProps;
  error?: string;
  loading?: boolean;
};
const BlogList = (props: BlogListProps): JSX.Element => {
  const { data, loading, error } = props;
  if (loading) {
    return <BlogListSkeleton />;
  }

  if (error) {
    return <BlogListError errorMsg={error} />;
  }

  if (!data) {
    return <BlogListEmpty />;
  }

  return <BlogListContent {...data} />;
};
export default BlogList;

import * as React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

import { ProductRows } from 'components/shared';
import { ProductCard } from 'components/product';
import { getProductListReturn } from 'api';
import { useGetProductListReturn } from 'composables';

export type HighlightProductProps = {
  title: string;
} & useGetProductListReturn;

type HeaderProps = {
  title?: string;
};
const Header = (props: HeaderProps): JSX.Element => {
  const { title } = props;

  return (
    <Typography
      variant="h6"
      component="h2"
      sx={{
        paddingTop: '25px',
        paddingBottom: '15px',
      }}
    >
      {title || <Skeleton width="10rem" />}
    </Typography>
  );
};

const ProductCardSkeleton = (): JSX.Element => {
  return (
    <Box sx={{ width: '300px', marginRight: '20px', display: 'inline-block' }}>
      <ProductCard />
    </Box>
  );
};

const HighlightProductSkeleton = (): JSX.Element => {
  return (
    <Box>
      <Header />
      <Box sx={{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
      </Box>
    </Box>
  );
};

type HighlightProductDisplayProps = {
  title: string;
  data: getProductListReturn;
};
const HighlightProductDisplay = (
  props: HighlightProductDisplayProps,
): JSX.Element => {
  const { title, data } = props;
  return (
    <Box>
      <Header title={title} />
      <ProductRows productList={data.data} />
    </Box>
  );
};

const HighlightProduct = (props: HighlightProductProps): JSX.Element => {
  const { title, data } = props;

  if (typeof data === 'undefined') {
    return <HighlightProductSkeleton />;
  }

  return <HighlightProductDisplay title={title} data={data} />;
};
export default HighlightProduct;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';

import { useIndexPromoBanner } from 'composables';
import PromoBanner from '../PromoBanner';

const StyledCarousel = styled(Carousel)({
  '& .control-dots': {
    marginBottom: '15px',
  },
  '& .control-dots .dot': {
    margin: '0 4px',
    boxShadow: 'none',
  },
  // remove slide arrow
  '& .control-next.control-arrow': {
    padding: '10px',
  },
  '& .control-next.control-arrow:before': {
    borderLeft: '8px transparent',
  },
  '& .control-prev.control-arrow': {
    padding: '10px',
  },
  '& .control-prev.control-arrow:before': {
    borderRight: '8px transparent',
  },
});

const PromoBannerSlide = (): JSX.Element => {
  const { data } = useIndexPromoBanner({});

  if (data) {
    return (
      <StyledCarousel
        showIndicators={data.length > 1}
        showStatus={false}
        showThumbs={false}
        emulateTouch
        swipeable
        infiniteLoop
        autoPlay
        stopOnHover
        interval={5000}
      >
        {data.map((d) => (
          <PromoBanner key={d.name} data={d} />
        ))}
      </StyledCarousel>
    );
  }
  return <PromoBanner />;
};
export default PromoBannerSlide;

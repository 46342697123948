import * as React from 'react';
import Link from 'next/link';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  Skeleton,
} from '@mui/material';
import { parsePreFormatISO } from 'utils/date';

import { BlogSummary } from 'types/blog';
import { getImgUrlReplaceBe } from 'utils/image';

type BlogCardTemplateProps = {
  children: React.ReactNode;
};
const BlogCardTemplate = (props: BlogCardTemplateProps): JSX.Element => {
  const { children } = props;
  return (
    <Grid item xs={12} sm={4}>
      <Card
        sx={{
          'boxShadow': '0px 3px 6px 0px #00000029',
          '&:hover': {
            position: 'relative',
            top: '-5px',
          },
        }}
      >
        {children}
      </Card>
    </Grid>
  );
};

const BlogCardSkeleton = (): JSX.Element => {
  return (
    <BlogCardTemplate>
      <CardActionArea>
        <Skeleton
          variant="rectangular"
          sx={{
            'borderRadius': '2px',
            // make card media square based on width
            '&:after': {
              content: '""',
              display: 'block',
              paddingBottom: '100%',
            },
            'display': 'block',
            'height': '100%',
            'backgroundSize': 'cover',
            'backgroundRepeat': 'no-repeat',
            'backgroundPosition': 'center',
          }}
        />
        <CardContent
          sx={{
            color: 'secondary.main',
            backgroundColor: 'common.lightGrey',
            padding: '10px',
          }}
        >
          <Typography variant="body1" component="p" gutterBottom>
            <Skeleton variant="text" />
            <Skeleton variant="text" width="70%" />
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Skeleton
                variant="text"
                width="4em"
                sx={{ backgroundColor: 'primary.main' }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </BlogCardTemplate>
  );
};

type BlogCardContentProps = Omit<BlogSummary, 'subTitle'>;
const BlogCardContent = (props: BlogCardContentProps): JSX.Element => {
  const { imageUrl, title, author, articleDate, slug } = props;

  const date = parsePreFormatISO(articleDate, 'display');

  return (
    <BlogCardTemplate>
      <Link href="/blog/[id]" as={`/blog/${slug}`} passHref>
        <CardActionArea component="a" title={title}>
          <Box
            sx={{
              'borderRadius': '2px',
              // make card media square based on width
              '&:after': {
                content: '""',
                display: 'block',
                paddingBottom: '100%',
              },
              'display': 'block',
              'height': '100%',
              'backgroundSize': 'cover',
              'backgroundRepeat': 'no-repeat',
              'backgroundPosition': 'center',
              'backgroundImage': `url(${getImgUrlReplaceBe({
                imgPath: imageUrl,
                transformation: 'w-300,h-300',
                encodeUri: true,
              })})`,
            }}
          />
          <CardContent
            sx={{
              color: 'secondary.main',
              backgroundColor: 'common.lightGrey',
              padding: '10px',
            }}
          >
            <Box sx={{ height: '3.5rem' }}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  fontFamily: 'fontFamilyThaiSerif',
                  maxHeight: '3rem', // 1.5 rem per line * 2 line
                  position: 'relative',
                  overflow: 'hidden',
                  paddingRight: '1rem',
                }}
              >
                {title}
              </Typography>
            </Box>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  component="p"
                  sx={{
                    fontWeight: 'fontWeightLight',
                    fontFamily: 'fontFamilyThaiSerif',
                    color: 'common.darkGrey',
                  }}
                >
                  | {author}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  component="p"
                  align="right"
                  sx={{
                    fontWeight: 'fontWeightLight',
                    fontFamily: 'fontFamilyThaiSerif',
                    color: 'common.darkGrey',
                  }}
                >
                  {date}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Link>
    </BlogCardTemplate>
  );
};

export type BlogCardProps = {
  data?: Omit<BlogSummary, 'subTitle'>;
};
const BlogCard = (props: BlogCardProps): JSX.Element => {
  const { data } = props;
  return data ? <BlogCardContent {...data} /> : <BlogCardSkeleton />;
};
export default BlogCard;

import { Caffe, CaffePromise } from 'apiclient';
import { AxiosRequestConfig } from 'axios';
import { PaginationMeta } from 'types/pagination';
import { BlogList } from 'types/blog';

const caffe = new Caffe();

export type getBlogListProps = { api?: 'getBlogList' } & {
  axiosConfig?: AxiosRequestConfig;
} & {
  page: number;
  pageSize?: number;
  getAll?: boolean;
};

export interface getBlogListReturn {
  data: BlogList;
  meta: PaginationMeta;
}

const blogList = async (props: getBlogListProps): CaffePromise<getBlogListReturn> => {
  const { axiosConfig, ...functionProps } = props;

  const defaultProps = {
    pageSize: 20,
  };
  const data: getBlogListProps = {
    ...defaultProps,
    ...functionProps,
  };

  return caffe.request({
    ...{
      method: 'GET',
      url: '/api/method/caff.e.blog.query.blog_list',
      params: {
        page: data.page,
        page_size: data.pageSize,
        get_all: data.getAll,
      },
    },
    ...axiosConfig,
  });
};
export default blogList;

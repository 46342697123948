import * as React from 'react';
import Link from 'next/link';
import { Box, Button, Container, Typography } from '@mui/material';

import {
  // BgVideo,
  PromoNoti,
  HighlightProduct,
  PromoBannerSlide,
} from 'components/shared';
// import { FirstOrderCode } from 'assets/Misc';
import { LogoCafnco } from 'assets/Branding';
import { BlogList } from 'components/blog';
import { useBlogList, useGetProductList } from 'composables';

const IndexOrg = (): JSX.Element => {
  const { isLoading: blogIsLoading, data: blogData } = useBlogList({
    page: 1,
    pageSize: 6,
  });
  const productBestSellersData = useGetProductList({
    page: 1,
    pageSize: 10,
    sortBy: 'bestSeller',
    showOutofStock: false,
    showSoldOut: false,
  });
  const productTopPicksData = useGetProductList({
    page: 1,
    pageSize: 10,
    sortBy: 'bestMatch',
    showOutofStock: false,
    showSoldOut: false,
  });
  const productNewArrivalsData = useGetProductList({
    page: 1,
    pageSize: 10,
    sortBy: 'newest',
    showOutofStock: false,
    showSoldOut: false,
  });

  return (
    <>
      {/* <BgVideo
        videoPoster="https://ik.imagekit.io/caffeinnne/videos/coffee_beans_on_tree.mp4/ik-thumbnail.jpg"
        videoSrc="https://ik.imagekit.io/caffeinnne/videos/coffee_beans_on_trees.m4v"
      ></BgVideo> */}
      <PromoNoti />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          top: 0,
          left: 0,
          zIndex: -20,
          backgroundImage: 'url(/image/coffee_harvested.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
        }}
      />
      {/* subtract 64px because of nav bar height */}
      <Box
        sx={{
          height: 'calc(100vh - 64px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          boxShadow: '0px 5px 20px -12px rgb(0 0 0 / 89%)',
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Box
            maxWidth="md"
            sx={{
              position: 'absolute',
              top: {
                xs: 'calc(50vh - 64px)',
                sm: 'calc(55vh - 64px)',
                md: 'calc(60vh - 64px)',
              },
            }}
          >
            <Box
              sx={{
                marginTop: '18px',
                width: '10em',
                marginBottom: '1.3rem',
                color: 'white',
              }}
            >
              <LogoCafnco width="100%" height="100%" />
            </Box>
            <Typography
              sx={{
                color: 'common.white',
                lineHeight: 1.2,
                fontSize: {
                  xs: '1.4rem',
                  md: '1.75rem',
                  xl: '2rem',
                },
                fontWeight: 200,
              }}
            >
              เลือกซื้อเมล็ดกาแฟจากโรงคั่วดี ๆ
              <br />
              ส่งตรงถึงมุมกาแฟของคุณ{' '}
              <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                #เมล็ดกาแฟสั่งcaf
              </Box>
            </Typography>
            <Link href="/shop" passHref>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  'marginTop': '2rem',
                  'backgroundColor': 'common.lightGrey',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    color: 'white',
                  },
                  'fontWeight': 'fontWeightMedium',
                  'fontSize': '1.1rem',
                  'boxShadow':
                    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                }}
              >
                เมล็ดกาแฟทั้งหมด
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          boxShadow: '0px 5px 20px -12px rgb(0 0 0 / 89%)',
        }}
      >
        <PromoBannerSlide />
      </Box>
      {/* <Box
        display="flex"
        justifyContent="center"
        sx={{
          width: '100%',
          backgroundColor: 'primary.main',
          paddingTop: {
            xs: 3,
            sm: 4,
            md: 5,
            lg: 7,
            xl: 10,
          },
          paddingBottom: {
            xs: 3,
            sm: 4,
            md: 5,
            lg: 7,
            xl: 10,
          },
          position: 'relative',
          boxShadow: '0px 5px 20px -12px rgb(0 0 0 / 89%)',
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px',
          marginTop: '-6px',
          zIndex: -40,
          // hover in not working on negative zIndex
          // https://stackoverflow.com/questions/35310779/why-does-the-negative-z-index-removes-the-hovering-capabilities-of-non-staticall
          // we should be able to elevate nav bar then align zindex properly
          // but it seems like a lot of work and need a bit of QA
          // just to do a really simple thing, so we just ignore it first
          // '&:hover': {
          //   backgroundColor: '#6A816B',
          // },
        }}
      >
        <Container maxWidth="md">
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                maxWidth: {
                  xs: '400px',
                  md: '500px',
                  lg: '600px',
                },
              }}
            >
              <FirstOrderCode width="100%" />
            </Box>
          </Box>
        </Container>
      </Box> */}
      <Container maxWidth="xl">
        <Box
          sx={{
            marginTop: 4,
            marginLeft: { xl: '40px', lg: '40px', md: 0 },
            marginRight: { xl: '40px', lg: '40px', md: 0 },
          }}
        >
          <HighlightProduct title="Best Sellers" {...productBestSellersData} />
          <HighlightProduct title="New Arrivals" {...productNewArrivalsData} />
          <HighlightProduct title="Top Picks" {...productTopPicksData} />
        </Box>
      </Container>

      {/* margin and padding bottom are for extending backgroundColor out to
          footer padding */}
      {blogData ? (
        <Box
          sx={{
            backgroundColor: 'common.lightGrey',
            // -250 is more than enough, it extend under footer (footer have higher zindex)
            marginBottom: '-250px',
            paddingBottom: '320px',
            marginTop: '50px',
          }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                marginLeft: { xl: '40px', lg: '40px', md: 0 },
                marginRight: { xl: '40px', lg: '40px', md: 0 },
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  paddingTop: '25px',
                  paddingBottom: '15px',
                }}
              >
                Blogs
              </Typography>
              <BlogList
                data={{ blogList: blogData.data }}
                loading={blogIsLoading}
              />
            </Box>
          </Container>
        </Box>
      ) : null}
    </>
  );
};
export default IndexOrg;
